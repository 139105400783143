import React from "react";
import { graphql } from "gatsby";

import Sidebar from "../components/BlogDetails/Sidebar";
import Layout from "../components/App/Layout";
import Seo from "../components/App/seo";
import { getDate, getMonth } from "utils/date-formatter";

const BlogDetails = (props) => {
  const post = props.data.markdownRemark;
  const { categories, tags } = props.pageContext;

  return (
    <Layout location="blog">
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image.replace("/static/", "/")}
        date={post.frontmatter.date}
      />
      <div className="bread-cumbs-area bread-cumbs-bg">
        <div className="diplay-table">
          <div className="display-table-cell" id="blog-title-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <h1>{post.frontmatter.title}</h1>
                  <p>{post.frontmatter.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="blog" className="our-blog main-blog bg-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12">
                  <div className="blog-details">
                    <div className="post-img">
                      <img
                        src={post.frontmatter.image.replace("/static/", "/")}
                        alt="blog-one"
                      />
                    </div>

                    <div className="blog-info">
                      <div className="date-box">
                        {getDate(post.frontmatter.date)}{" "}
                        <span className="month">
                          {getMonth(post.frontmatter.date)}
                        </span>
                      </div>

                      <div className="title-meta">
                        <h2>{post.frontmatter.title}</h2>
                        <div className="post-meta">
                          <i className="fa fa-tags"></i>
                          {" "}Tags:{" "}
                          <ul className="d-inline">
                            {
                              post.frontmatter.tags.map((tag) => (
                                <li>
                                <a href={`/blog-list/${tag}`}>
                                  {tag}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      className="post-content"
                      dangerouslySetInnerHTML={{ __html: post.html }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <Sidebar categoriesData={categories} tagsData={tags} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogDetails;
export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date
        description
        image
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
